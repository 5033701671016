<template>
  <div class="mi">
    <div class="miNav-box">
      <div class="miNav">
        <div class="miNav-list">
          <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title">
            <div class="miNav-list-item" @click="navChange(item.title)">
              <p :style="{color:title==item.title?'#92D5DE':'#C6C6C6',
            fontWeight:title==item.title?'bold':'',}">{{item.title}}</p>
              <div class="miNav-list-item-border" v-if="title==item.title"></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <router-view style="padding-top:50px" :navTitle="title" @navTitleChange="navChange"></router-view>
    <div class="miFooter">
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2022010263号-5</a>
      <div class="homeFooter-left-bottom-list">
        <div>
          <img src="../../assets/icon.png" alt="">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021102001744&token=cf01ed85-4879-4bb9-8167-c6038d5d3e1f">闽公网安备35021102001744号</a>
        </div>
      </div>
      <p>Copyright 2020-2023 Qiansay 版权所有</p>
      <a @click="open">增值电信业务经营许可证：闽B2-20221070</a>
    </div>
  </div>
</template>
<script>
import imageUrl from "@/assets/imageUrl.png"
export default {
  data() {
    return {
      title: '首页',
      navList: [
        {
          title: '首页',
          name: 'mHome'
        },
        {
          title: '产品介绍',
          name: 'mIntroduce'
        },
        {
          title: '防丢牌',
          name: 'mLostCard'
        },
        {
          title: '养宠知识',
          name: 'mKnowledge'
        },
        {
          title: '关于我们',
          name: 'mAbout'
        },
        {
          title: '产品下载',
          name: 'mDownload'
        },
      ],
    }
  },
  methods: {
    navChange(title) {
      this.title = title
      window.scrollTo(0, 0);
    },
    open() {
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        image.style.margin = "0 auto"
        image.style.display = "block"
        const newWin = window.open("", "_blank")
        newWin.document.write(image.outerHTML)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
.mi {
  .miNav-box {
    width: 100%;
    height: 50px;
    position: fixed;
    background-color: #fff;
    z-index: 1300;
  }
  .miNav {
    position: fixed;
    padding-left: 10px;//30px;
    padding-right: 10px;//30px;
    box-sizing: border-box;
    width: 100%;
    .miNav-list {
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      margin-top: 13.5px;
      justify-content: space-between;
      .miNav-list-item {
        p {
          font-size: 13px;//16px;
          letter-spacing: 1px;
        }
        .miNav-list-item-border {
          height: 3px;
          background-color: #92d5de;
          border-radius: 1.5px;
        }
      }
    }
  }
  .miFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 23px;
    p,
    a {
      // font-weight: bold;
      color: #666666;
      font-size: 12px;
      margin-bottom: 8.5px;
      letter-spacing: 1.4px;
    }
    .homeFooter-left-bottom-list {
      display: flex;
      align-items: center;
      margin-bottom: 8.5px;
      div {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }
        a {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>